import React, { useState, useEffect } from "react";
import { Button, Typography, Modal, Input, message } from "antd";
import {
  PrinterOutlined,
  EditOutlined,
  SyncOutlined,
  DownloadOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import axios from "axios";

const { Text } = Typography;

export default function ClinicalNotesModal({
  isOpen,
  onClose,
  data,
  view,
  id,
  fhir_id,

}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState(data?.clinical_note);
  const [sendEhrLoading,setSendEhrLoading]=useState(false);

  useEffect(() => {
    console.log(data?.clinical_note)
    try {
      setEditableData(data?.clinical_note);
    } catch (error) {
      console.error("Error parsing updated data: ", error);
    }
  }, [data]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleContentChange = (section, index, content) => {
    setEditableData((prevData) => ({
      ...prevData,
      [section]: prevData[section].map((item, idx) =>
        idx === index ? { ...item, content } : item
      ),
    }));
  };

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/download_note/${id || data?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          responseType: "blob", // Ensure the response is treated as a file
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const url = window.URL.createObjectURL(blob);

      // Trigger file download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${data?.patient_name}_ClinicalNote.docx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      message.error("Error downloading the file. Please try again.");
    }
  };

  const sendToEhr = async()=>{

    setSendEhrLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/import/`,{
          fhir_id: data?.fhir_id,
          clinical_note: JSON.stringify(editableData),

        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
         
        }
      );
      message.success("EHR has been synced");
      setSendEhrLoading(false);
  
     
     
    } catch (error) {
      message.error("Error Syncing EHR, Please try again.");
      setSendEhrLoading(false);
    }

  }

  const handlePrint = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/print_note/${id || data?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          responseType: "blob", 
        }
      );
  
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const printWindow = window.open(url, "_blank");
      if (printWindow) {
        printWindow.addEventListener("load", () => {
          printWindow.print();
        });
      } else {
        console.error("Failed to open a new window for printing.");
        message.error("Failed to open a new window for printing. Please check your browser settings.");
      }
    } catch (error) {
      console.error("Error fetching the file for printing:", error);
      message.error("Error fetching the file for printing. Please try again.");
    }
  };
  

  const handleSave = async () => {
    setIsEditing(false);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/save_clinical_note/`,
        {
          id: id ? id : data?.id,
          note: JSON.stringify(editableData),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response) {
        message.success("Clinical note saved successfully!");
      } else {
        message.error("Failed to save clinical note.");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        message.error("You are not authorized! Please login");
        return
      }
      if (error?.response?.status === 403) {
        message.error("You are not authorized! please verified your email");
        return
      }
       else {
        message.error("Error saving clinical note. Please try again.");
      }
    }
  };

  const renderSection = (sectionName, sectionData) => (
    <div className="mb-4">
      <Text strong className="text-lg capitalize">
        {sectionName}
      </Text>
      {sectionData.map((item, index) => (
        <div key={index} className="mt-2">
          <Text strong>{item.title}:</Text>
          {isEditing ? (
            <Input.TextArea
              value={item.content}
              onChange={(e) =>
                handleContentChange(sectionName, index, e.target.value)
              }
              autoSize
              className="text-gray-700 mt-1"
            />
          ) : (
            <pre className="text-gray-700 mt-1 whitespace-pre-wrap">
              {item.content}
            </pre>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <Modal
      open={isOpen}
      onCancel={() => onClose(!isOpen)}
      footer={null}
      className="w-full"
      maskClosable={false}
    >
      <div className="max-h-[90vh] p-4 rounded-lg overflow-hidden">
        <div className="bg-[#0093d0] text-white py-3 px-4 rounded-t-lg text-center">
          <Text className="block text-white text-xl font-medium">
            Clinical Notes (SOAP Note)
          </Text>
        </div>

        <div className="bg-gray-100 px-6 py-3 flex justify-between">
          <Text>Patient Name: {data?.patient_name}</Text>
          <Text>Date: {data?.created_at}</Text>
        </div>

        {data && (
          <div
            className="p-6 w-full overflow-y-auto border border-gray-100"
            style={{ maxHeight: "50vh" }}
          >
            {["subjective", "objective", "assessment", "plan","recommendation"].map((section) =>
              renderSection(
                section,
                (editableData && editableData[section]) || []
              )
            )}
          </div>
        )}

        {!view && (
          <div className="py-4 border-t flex justify-between items-center">
            <div className="flex gap-1">
              <Button
                icon={<PrinterOutlined />}
                type="text"
                onClick={handlePrint}
              />

              <Button
                icon={<DownloadOutlined />}
                onClick={handleDownload}
                className="border-none shadow-none"
              />
              {isEditing ? (
                <Button
                  icon={<SaveOutlined />}
                  className="border-none shadow-none"
                  onClick={handleSave}
                />
              ) : (
                <Button
                  icon={<EditOutlined />}
                  type="text"
                  onClick={handleEdit}
                />
              )}
            </div>
            <div className="flex max-md:flex-col gap-3">
              {data?.fhir_id &&<Button
                type="primary"
                icon={<SyncOutlined />}
                loading={sendEhrLoading}
                onClick={sendToEhr}
                className="primary h-12 px-2 text-center"
              >
                EHR/EMR Sync
              </Button>}
              <Button
                type="primary"
                className="primary h-12 px-2"
                onClick={() => onClose(!isOpen)}
              >
                Regenerate Notes
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
