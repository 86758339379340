import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const token=localStorage.getItem("accessToken");
const AUTH_HEADERS = {
  headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
};

export const getAllChats = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get_all_chats`, 
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
      });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllFavChats = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get_all_fav_chats/`,  {
      headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
    });
    return response;
  } catch (error) {
    throw error;
  }
};



export const sendMessageToBot = async (session_id:any, question:any) => {
  try {
    const is_new = !session_id;
    const response = await axios.post(`${API_BASE_URL}/qabot`, { session_id, question, is_new }, {
      headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteFavoriteChat = async (session_id:any, category:any, question:any) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/delete_fav_chat`, { session_id, category, question }, {
      headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const makeChatFavorite = async (session_id:any, category:any, question:any) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/make_chat_fav`, { session_id, category, question },  {
      headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
    });
    return response;
  } catch (error) {
    throw error;
  }
};
