import React, { useState } from "react";
import { Modal, Tabs, Table, Descriptions } from "antd";

const EhrPopup = ({ visible, onClose, data }) => {
  const { patient, laboratory, vitals, encounter, clinical_notes } = data;

  const patientInfo = (
    <Descriptions title="Patient Info" bordered column={2}>
      <Descriptions.Item label="Name">{patient.full_name}</Descriptions.Item>
      <Descriptions.Item label="Phone">{patient.phone_number}</Descriptions.Item>
      <Descriptions.Item label="Gender">{patient.gender}</Descriptions.Item>
      <Descriptions.Item label="DOB">{patient.dob}</Descriptions.Item>
      <Descriptions.Item label="Marital Status">{patient.marital_status}</Descriptions.Item>
      <Descriptions.Item label="Doctor">{patient.general_practitioner}</Descriptions.Item>
    </Descriptions>
  );

  const labColumns = [
    { title: "Test Name", dataIndex: "name", key: "name" },
    { title: "Issued Date", dataIndex: "issued", key: "issued" },
    { title: "Value", dataIndex: "value", key: "value" },
  ];

  const vitalsColumns = [
    { title: "Type", dataIndex: "code", key: "code" },
    { title: "Issued Date", dataIndex: "issued", key: "issued" },
    {
      title: "Value",
      key: "value",
      render: (record) =>
        record.component ? (
          record.component.map((comp) => (
            <div key={comp.text}>
              {comp.text}: {comp.value} {comp.unit}
            </div>
          ))
        ) : (
          <div>
            {record.value} {record.unit}
          </div>
        ),
    },
  ];

  const clinicalColumns = [
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Author", dataIndex: "author", key: "author" },
    {
      title: "Details",
      key: "details",
      render: (record) =>
        record.attachment.length > 0 ? record.attachment[0].value : "No details",
    },
  ];

  return (
    <Modal open={visible} onCancel={()=>onClose(!visible)} width={800} footer={null}>
      {patientInfo}
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Laboratory" key="1">
          <Table dataSource={laboratory} columns={labColumns} rowKey="name" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Vitals" key="2">
          <Table dataSource={vitals} columns={vitalsColumns} rowKey="issued" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Clinical Notes" key="3">
          <Table dataSource={clinical_notes} columns={clinicalColumns} rowKey="date" />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default EhrPopup;
